export default {
  title: 'Financeiro',
  default: 'hom',
  shortName: 'FIN',
  menu: [
    {
      id: 'financeiro-home',
      title: 'Resumo',
      icon: 'chart-simple',
      style: 'light',
      url: '/#/financeiro'
    },
    {
      id: 'financeiro-contas',
      title: 'Financeiro',
      icon: 'dollar-sign',
      style: 'light',
      submenu: [
        {
          id: 'financeiro-receitas',
          title: 'A Pagar',
          icon: 'money-check',
          style: 'light',
          url: '/#/financeiro/contas#pagar'
        },
        {
          id: 'financeiro-despesas',
          title: 'A Receber',
          icon: 'money-check',
          style: 'light',
          url: '/#/financeiro/contas#receber'
        },
        {
          id: 'financeiro-extrato',
          title: 'Extrato',
          icon: 'receipt',
          style: 'light',
          url: '/#/financeiro/contas#extrato'
        }
      ]
    },
    {
      id: 'financeiro-compras',
      title: 'Compras',
      icon: 'file-check',
      style: 'light',
      submenu: [
        {
          id: 'financeiro-compras',
          title: 'Compras',
          disabled: true,
          icon: 'money-check',
          style: 'light',
          url: '/#/financeiro/compras'
        },
        {
          id: 'financeiro-fornecedores',
          title: 'Fornecedores',
          icon: 'money-check',
          style: 'light',
          url: '/#/pessoas?paper=fornecedor'
        }
      ]
    },
    {
      id: 'cc',
      title: 'Centro de Custo',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/financeiro/cc'
    },
    {
      id: 'label-def',
      diviser: true,
      title: 'Definições',
      style: 'light',
    },
    {
      id: 'financeiro-config',
      title: 'Definições',
      icon: 'clipboard-check',
      style: 'light',
      submenu: [
        {
          id: 'financeiro-categorias',
          title: 'Categorias',
          icon: 'list-tree',
          style: 'light',
          url: '/#/financeiro/categorias'
        },
        {
          id: 'financeiro-contas',
          title: 'Bancos',
          icon: 'money-bill-trend-up',
          style: 'light',
          url: '/#/cadastro/bancos'
        }
      ]
    },
    /*{
      id: 'relatorios',
      title: 'Relatórios',
      icon: 'user-chart',
      style: 'light',
      submenu: [
        {
          id: 'financeiro-receitas',
          title: 'XXX',
          icon: 'money-check',
          style: 'light',
          submenu: [
            {
              id: 'financeiro-receitas',
              title: 'Contas',
              icon: 'money-check',
              style: 'light',
              url: '/#/financeiro/contas'
            }
          ]
        },
        {
          id: 'financeiro-despesas',
          title: 'YYY',
          icon: 'money-check',
          style: 'light',
          url: '/#/financeiro/contas2'
        }
      ]
    }*/
  ]
}
