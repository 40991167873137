<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultMixin from '../mixin'

export default {
  name: 'FinanceiroPage',
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Financeiro',
    name: 'Financeiro'
  }
}
</script>

<template>
  <layout :menu="menu" menu-active="tasks" menu-name="FIN" :folded="false" no-title hfull>
    <router-view />
  </layout>
</template>
